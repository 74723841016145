.App {
  text-align: center;
}

body {
  background: #eadec5 !important;
}

.page-header::before {
  background-image: url("./images/textures/old_moon.png");
  content: "";
  animation: grain 8s steps(10) infinite;
  position: fixed;
  height: 300%;
  width: 300%;
  top: -110%;
  left: -50%;
  opacity: 0.46;
  z-index: -1;
}

@keyframes grain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}

/* --------------- Top section below --------------- */

@media screen and (min-width: 1215px) {
  #name {
    font-family: "National2-Black";
    font-size: 87px;
    color: #27305e;
    padding: 30px 0px 0px 50px;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1026px) and (max-width: 1214px) {
  #name {
    font-family: "National2-Black";
    font-size: 84px;
    color: #27305e;
    padding: 30px 0px 0px 50px;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1025px) {
  #name {
    font-family: "National2-Black";
    font-size: 80px;
    color: #27305e;
    padding: 30px 0px 0px 50px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  #name {
    font-family: "National2-Black";
    font-size: 55px;
    color: #27305e;
    padding: 30px 0px 0px 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
  }
}

#emailsymbol {
  font-family: "Vary Light";
  font-size: 34px;
  color: #27305e;
  margin-top: -13px;
  cursor: pointer;
}

#emailcopytext {
  font-family: "Recoleta-Regular";
  color: #27305e;
}

#linkedin {
  width: 32px;
}

#github {
  width: 32px;
}

@media screen and (min-width: 768px) {
  #linkiconrow {
    margin-left: 55px;
  }
}

@media screen and (max-width: 767px) {
  #linkiconrow {
    justify-content: center;
  }
}

.ui.grid > [class*="three column"].row > .column.linkicons {
  width: 60px !important;
}

.ui.image img {
  max-width: 33px;
}

.hvr-skew {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-skew:hover,
.hvr-skew:focus,
.hvr-skew:active {
  -webkit-transform: skew(-7deg);
  transform: skew(-7deg);
}

/* Skew Forward */
.hvr-skew-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hvr-skew-forward:hover,
.hvr-skew-forward:focus,
.hvr-skew-forward:active {
  -webkit-transform: skew(-7deg);
  transform: skew(-7deg);
}

/* ------------ Nav section below ------------- */

@media screen and (max-width: 1020px) {
  #nav {
    display: none;
  }
}

@media screen and (min-width: 1252px) {
  .navButton {
    font-family: "Recoleta-Regular";
    font-size: 24px;
  }
}

@media screen and (min-width: 1021px) and (max-width: 1251px) {
  .navButton {
    font-family: "Recoleta-Regular";
    font-size: 21px;
  }
}

@media screen and (min-width: 1021px) {
  #nav {
    position: fixed;
    top: 55px;
    right: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 2;
  }
}

div a.active {
  border-bottom: 1px dotted #27305e;
}

#aboutnav {
  color: #27305e;
}

#aboutnav:hover {
  color: #c26363;
}

#projectsnav {
  color: #27305e;
}

#projectsnav:hover {
  color: #c26363;
}

/* --------- General about section below ------------ */

#aboutgrid {
  margin-top: 8%;
  margin-bottom: 20px;
}

/* ------- Blurb section below ---------- */

@media screen and (min-width: 1215px) {
  #greeting {
    font-family: "Recoleta-Regular";
    color: #27305e;
    font-size: 33px;
  }

  #blurb {
    font-family: "Recoleta-Regular";
    color: #27305e;
    line-height: 49px;
    font-size: 23px;
  }

  #photocolumn {
    margin: 0px 30px 0px 30px;
  }

  #pigspan {
    position: absolute;
    left: 80%;
  }
}

@media screen and (min-width: 1026px) and (max-width: 1214px) {
  #greeting {
    font-family: "Recoleta-Regular";
    color: #27305e;
    font-size: 30px;
  }

  #blurb {
    font-family: "Recoleta-Regular";
    color: #27305e;
    line-height: 45px;
    font-size: 21px;
  }

  #photocolumn {
    margin: 0px 30px 0px 30px;
    transform: scale(0.8);
  }

  .shape {
    transform: scale(1.05);
  }

  #pigspan {
    position: absolute;
    left: 80%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1025px) {
  #greeting {
    font-family: "Recoleta-Regular";
    color: #27305e;
    font-size: 27px;
  }

  #blurb {
    font-family: "Recoleta-Regular";
    color: #27305e;
    line-height: 42px;
    font-size: 19px;
  }

  #photocolumn {
    margin: 0px 30px 0px 30px;
    transform: scale(0.8);
  }

  .shape {
    transform: scale(1.05);
  }

  #pigspan {
    position: absolute;
    left: 80%;
  }
}

@media screen and (max-width: 767px) {
  #greeting {
    font-family: "Recoleta-Regular";
    color: #27305e;
    font-size: 19px;
  }

  #blurb {
    font-family: "Recoleta-Regular";
    color: #27305e;
    line-height: 39px;
    font-size: 17px;
  }

  #blurbcolumn {
    max-width: 600px;
    padding: 0rem 2rem 0rem 2rem !important;
  }

  #photocolumn {
    margin: 0px 30px 0px 30px;
  }

  #pigspan {
    position: absolute;
    left: 73%;
    top: -2%;
  }
}

#blurbcolumn {
  margin: 0px 0px 0px 105px;
}

#pigemoji {
  font-size: 17px;
  margin-left: -4px;
}

.ui.toggle.checkbox.pigtoggle {
  /* transform: scale(.55); */
  transform: scale(0.45);
  position: relative;
  top: 8px;
}

.ui.toggle.checkbox.pigtoggle input:focus:checked ~ .box:before,
.ui.toggle.checkbox.pigtoggle input:focus:checked ~ label:before {
  background-color: #27305e !important;
}

/* ------- Emoji Hand wave section below ---------- */

#wave {
  animation-name: wave-animation;
  animation-duration: 4.5s;
  animation-iteration-count: 1;
  animation-delay: 0s;
  transform-origin: 30% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-8deg);
  }
  20% {
    transform: rotate(8deg);
  }
  30% {
    transform: rotate(-8deg);
  }
  40% {
    transform: rotate(8deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* --------------- Photo section below --------------- */

/* Photocolumn moved to about section media queries */
#photocolumn {
  margin: 0px 30px 0px 30px;
}

.buttontext {
  transform: skew(-9deg);
  padding-top: 2px;
}

.button.ui.button.scrollbutton {
  width: 155px;
  height: 45px;
  transform: skew(9deg);
  font-family: "Recoleta-Regular";
  background: transparent;
  color: #27305e;
  display: flex;
  margin: auto;
  border: 2px solid;
  box-shadow: 3px 3px 0 #888;
  cursor: pointer;
  outline: transparent;
  transition: box-shadow 0.2s ease;
  padding: 4 4 0 0;
  justify-content: center;
}

.button.ui.button.scrollbutton:hover {
  box-shadow: 2px 2px 0 #888;
}

.button.ui.button.scrollbutton:active {
  box-shadow: 0px 0px 0;
}

/* ------- Picture blob for photo ------------- */

#aboutsection {
  --spin: 80s;
}

.bgnowimage {
  width: 142%;
  height: 142%;
  position: absolute;
  left: -21%;
  top: -21%;
  background: url("./images/about_images/currentPicture.jpg");
  background-size: 94%;
  background-position: center center;
  display: flex;
  animation: spin var(--spin) linear infinite reverse;
  opacity: 1;
  z-index: 2;
}

.box {
  width: 225px;
  height: 225px;
  display: flex;
  margin: auto;
}

.spin-container {
  width: 100%;
  height: 100%;
  animation: spin var(--spin) linear infinite;
}

.shape {
  width: 100%;
  height: 100%;
  transition: border-radius 1s ease-out;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  animation: morph 8s ease-in-out infinite both alternate;
  position: absolute;
  overflow: hidden;
  z-index: 5;
}

@keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 60% 30% 70% 40%;
  }
  100% {
    border-radius: 70% 60%;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

/* ----------- Custom Blobslider Styling -------------  */

.blobmeter {
  width: 32px;
  height: 32px;
  margin-top: -5px;
  display: inline-block;
}

#sliderspanholder {
  display: flex;
  margin: auto;
  padding-top: 25px;
}

#sliderholder {
  display: flex;
  margin: auto;
}

#blobslider {
  height: 23px;
  -webkit-appearance: none;
  display: flex;
  margin: 0 0 0 0;
  direction: rtl;
  background: transparent;
}

#blobslider:focus {
  outline: none;
}

#blobslider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #27305e;
  border-radius: 5px;
  border: none;
}

#blobslider::-webkit-slider-thumb {
  border: 1px solid #27305e;
  height: 21px;
  width: 37px;
  border-radius: 30px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9px;
}

#blobslider:focus::-webkit-slider-runnable-track {
  background: #27305e;
}

#blobslider::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #27305e;
  border-radius: 2px;
  border: 0px solid #ffffff;
}

#blobslider::-moz-range-thumb {
  box-shadow: 0px 0px 0px #ffffff;
  border: 1px solid #27305e;
  height: 16px;
  width: 16px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
}

#blobslider::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

#blobslider::-ms-fill-lower {
  background: #27305e;
  border: 0px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 0px #000000;
}

#blobslider::-ms-fill-upper {
  background: #27305e;
  border: 0px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 0px #000000;
}

#blobslider::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #ffffff;
  border: 1px solid #27305e;
  height: 16px;
  width: 16px;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
}

#blobslider:focus::-ms-fill-lower {
  background: #27305e;
}

#blobslider:focus::-ms-fill-upper {
  background: #27305e;
}

/* --------------- Project container below ---------------- */

@media screen and (max-width: 1020px) {
  #projects {
    padding-top: 0px;
  }
}

@media screen and (min-width: 1021px) {
  #projects {
    padding-top: 330px;
  }
}

@media screen and (max-width: 937px) and (min-width: 767px) {
  .ui.grid > [class*="three column"].row > .column.projectcolumn {
    min-width: 74%;
    padding-bottom: 90px;
  }
}

/* The below media query is for handling widths smaller than 767px - need to fix */

@media screen and (max-width: 766px) {
  .ui.grid > [class*="three column"].row > .column.projectcolumn {
    /* min-width: 74%; */
    /* padding: 3rem 3rem!important; */
    padding: 3rem 3rem !important;
    min-width: 90% !important;
  }
}

@media screen and (min-height: 900px) {
  #projectrow {
    padding-top: 145px;
    padding-bottom: 270px;
  }
}
@media screen and (max-height: 899px) {
  #projectrow {
    padding-top: 145px;
    padding-bottom: 220px;
  }
}
@media screen and (max-height: 830px) {
  #projectrow {
    padding-top: 145px;
    padding-bottom: 190px;
  }
}
@media screen and (max-height: 790px) {
  #projectrow {
    padding-top: 145px;
    padding-bottom: 150px;
  }
}

#projectcontainer {
  border: 0;
  box-shadow: none;
}

#projectgrid {
  justify-content: center;
  margin-bottom: 175px;
  background: transparent;
}

div#projectcontainer.ui.segment {
  background: transparent;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

#projectsheader {
  font-family: "Recoleta-Regular";
  color: #27305e;
}

.ui.grid > [class*="three column"].row > .column.projectcolumn {
  width: 24% !important;
  margin: 0px 45px 0px 45px !important;
}

h2.ui.header.projecttitle {
  font-family: "Recoleta-Regular";
  color: #27305e;
  text-align: center;
  font-size: 24px;
}

.projectblurb {
  font-family: "Recoleta-Regular";
  color: #27305e;
  margin: 0 0 18px;
  line-height: 18px;
  font-size: 14px;
}

span.techused {
  display: flex;
  flex-direction: row;
  width: 45px;
  justify-content: center;
  text-align: center;
}

/* ------------ PROJECT TECH ROW -------------------------- */

div.three.column.row.techiconrow {
  justify-content: center;
  padding-bottom: 0px;
}

.ui.grid > [class*="three column"].row > .column.techicons {
  width: 61px !important;
}

div.two.column.row.techiconrow {
  justify-content: center;
  padding-bottom: 0px;
}

.ui.grid > [class*="two column"].row > .column.techicons {
  width: 59px !important;
}

img.techimage {
  width: inherit;
}

.projectdiv {
  text-align: center;
  margin-top: 20px;
}

.linkdivider {
  font-family: "Recoletta-Regular";
  font-size: 28px;
  color: #27305e;
  vertical-align: sub;
  margin: 0px 3px 0px 3px;
}

.projectlink {
  font-family: "Recoleta-Regular";
  color: #27305e;
  text-align: center;
  font-size: 19px;
}

.projectlink:hover {
  color: #c26363;
}

#lifeletterimage {
  border-radius: 4px;
  box-shadow: 2px 2px 6px grey;
}

#quizimage {
  border-radius: 6px;
  box-shadow: 2px 2px 6px grey;
}

#spaceimage {
  border-radius: 8px;
  box-shadow: 2px 2px 6px grey;
}

/* Preloads gifs and hides them to prevent on hover flicker */
body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url("./images/projects_images/lifeletter.gif")
    url("./images/projects_images/quiz.gif")
    url("./images/projects_images/space.gif");
}

#spaceimage:hover {
  content: url("./images/projects_images/space.gif");
}

#lifeletterimage:hover {
  content: url("./images/projects_images/lifeletter.gif");
}

#quizimage:hover {
  content: url("./images/projects_images/quiz.gif");
}

.ui.list .list > a.item,
.ui.list > a.item {
  color: #27305e !important;
}

/* --------------- Fonts below ---------------- */

@font-face {
  font-family: "Vary Light";
  font-style: normal;
  font-weight: normal;
  src: local("Vary Light"), url("./fonts/varylight.woff") format("woff");
}

@font-face {
  font-family: "Recoleta-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Recoleta-Regular"),
    url("./fonts/Recoleta-Regular.woff") format("woff");
}

@font-face {
  font-family: "National2-Black";
  font-style: normal;
  font-weight: normal;
  src: local("National2-Black"),
    url("./fonts/National2-Black.woff") format("woff");
}
